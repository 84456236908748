<template>

  <div class="body1">

    <div class="bd-callout bd-callout-default">
      <p class="left">
        <button class="btn btn-link btn-sm black-txt" @click="goBack">
          <arrow-left-icon></arrow-left-icon>
        </button>
        <span class="right">
          TERMS & CONDITIONS
        </span>
      </p>
    </div>

    <pre></pre>

    <div class="bd-callout bd-callout-default list">
      <h3>TERMS AND CONDITIONS</h3>
      
      <p class="bold">A. INTRODUCTION</p>
      
      <p>
        1. By using and/or visiting any section (including sub-domains) of the tapabet website or any other websites or applications that we own (the “Website”) and/or registering on the Website, you agree to be bound by
      </p>

      <ol>
        <li>These Terms and Conditions;</li>
        <li>Our privacy policy</li>
        <li>(iii) our cookies and</li>
        <li>(iv) the rules applicable to our betting or gaming products as further referenced at paragraph 2 below (together the "Terms"), and are deemed to have accepted and understood all the Terms</li>
      </ol>
      
      <p>
        Please read the Terms carefully and if you do not accept the Terms, do not use the Website. The Terms shall also apply to all telephone betting and betting or gaming via mobile devices including downloadable applications to a mobile device (as if references to your use of the Website were references to your use of our telephone betting and/or mobile devices betting facilities).
      </p>
      
      <p>
        2. Where you play any game, or place a bet or wager, using the Website, you accept and agree to be bound by, the Rules which apply to the applicable products available on the Website from time to time. The Rules can be found under the Help tab of the applicable section of the Website, or at selected outlets.
      </p>
      <p>
        4. We may need to change the Terms from time to time for a number of reasons (including complying with applicable laws and regulations, and regulatory requirements). Any minor or insubstantial changes may be made at any time and you are advised to review the Terms on a regular basis. The most up to date Terms will be available on the Website. All major changes will be notified to you in advance of such changes taking effect. If any change is unacceptable to you, you should cease using the Website and/or close your account. If, however, you continue to use the Website after the date on which the changes to the Terms come into effect, you will be deemed to have accepted those changes.
      </p>
      <p>
        5. Reference to "you", "your" or the "customer" is reference to any person using the Website or the services of tapabet and/or any registered customer of tapabet.
      </p>
      <p>
        6. As you are aware, the right to access and/or use the Website (including any or all of the products offered via the Website) may be illegal in certain countries (including, for example, the USA). You are responsible for determining whether you’re accessing and/or use of the Website is compliant with applicable laws in your jurisdiction and you warrant to us that gambling is not illegal in the territory where you reside.
      </p>
      <p>
        7. tapabet is committed to providing excellent customer service. As part of that commitment, tapabet is committed to supporting responsible gambling. Although tapabet will use its reasonable endeavours to enforce its responsible gambling policies, tapabet does not accept any responsibility or liability if you nevertheless continue gambling and/or seek to use the Website with the intention of deliberately avoiding the relevant measures in place and/or tapabet is unable to enforce its measures/policies for reasons outside of tapabet ’s reasonable control.
      </p>

      <p class="bold">B. YOUR tapabet ACCOUNT</p>
      <p><strong>1. Application</strong></p>
      <p>
        1.1 All applicants must be over 18 years of age and of legal capacity to place a bet/wager or register with tapabet . tapabet reserves the right to ask for proof of age from any customer and suspend their account until satisfactory documentation is provided. tapabet takes its responsibilities in respect of under age and responsible gambling very seriously.
      </p>
      <p>
        1.2 All information supplied when registering with the site MUST be accura1.4 By accepting the Terms and/or registering to use the Website you hereby agree that we shall be entitled to conduct any and all such identification, credit and other verification checks from time to time that we may require and/or are required by applicable laws and regulations and/or by the relevant regulatory authorities for use of the Website and our products generally. You agree to provide all such information as we require in connection with such verification checks. We shall be entitled to suspend or restrict your account in any manner that we may deem in our absolute discretion to be appropriate, until such time as the relevant checks are completed to our satisfaction.te and complete in all respects. Where this is not the case, we reserve the right to suspend the account and treat any deposits into the gambling account as being invalid (and any winnings arising from such deposit as void). Where an account is suspended, the relevant customer should contact us.
      </p>
      <p>
        1.3 tapabet may confirm a customer’s address by posting an address verification letter to the customer. Enclosures may include a rule book, leaflet and telebet card. All correspondence will be discreet and the envelope will not display any reference to tapabet. When such correspondence is initiated, all offers and withdrawal requests may remain pending until the address has been confirmed as correct.
      </p>
      <p>
        1.4 By accepting the Terms and/or registering to use the Website you hereby agree that we shall be entitled to conduct any and all such identification, credit and other verification checks from time to time that we may require and/or are required by applicable laws and regulations and/or by the relevant regulatory authorities for use of the Website and our products generally. You agree to provide all such information as we require in connection with such verification checks. We shall be entitled to suspend or restrict your account in any manner that we may deem in our absolute discretion to be appropriate, until such time as the relevant checks are completed to our satisfaction.
      </p>
      <p>
        1.5 As part of the registration process, we may supply your information details to authorised credit reference agencies to confirm your identity and payment card details. You agree that we may process such information in connection with your registration.
      </p>
      <p>
        1.6 Customers may open only one account. Should we identify any customer with more than one account we reserve the right to treat any such accounts as one joint account.
      </p>

      <p>
        1.7 Customers must keep their registration and account details up to date. This, and your account information, may be amended in the Personal section of the Website. If you require any assistance, please contact us.
      </p>

      <p><strong>2. Account Details</strong></p>
      <p>
        2.1 tapabet allows all its customers to choose their own username and password combination. Customers must keep this information secret and confidential as you are responsible for all bets/wagers placed on your account and any other activities taking place on your account.
      </p>
      <p>
        2.2 Bets will stand if your username and password have been entered correctly or if your account has been accessed via Touch ID or the ‘Keep me logged in’ facility (whether or not authorised by you), subject to there being sufficient funds in the account. 
      </p>
      <p>
        2.3 If, at any time, you feel a third party is aware of your user name and/or password you should change it immediately via the Website. Should you forget part or all of your combination, please contact us.
      </p>
      <p>
        2.4 If you activate Touch ID and/or the ‘Keep me logged in’ facility and feel that a third party could be accessing your account via either of these methods then you should immediately disable Touch ID and/or the ‘Keep me logged in’ facility from all of your devices and contact us. You are responsible for the security of your device(s) and for all bets/wagers placed on your account and any other activities taking place on your account.
      </p>
      <p>
        2.5 For bets placed by telephone, you are responsible for all transactions where your name and account number or name and username are correctly quoted (whether or not authorised by you). If you nominate another person as an authorised user of your account, you shall be responsible for all transactions such person makes using the relevant account details. Should you lose your account details or feel that someone else may have your account details, please contact us.
      </p>
      <p>
        2.6 Please note that cardholder details and any other sensitive data should never be sent to us by unencrypted email. 
      </p>
      <p>
        2.7 The current balance and transaction history of your account may be viewed at any time once you have logged into your account on the Website. 
      </p>

      <p><strong>3. Personal Details</strong></p>
      <p>
        3.1 tapabet will comply with applicable data protection laws in respect of the personal information you supply to us. Your personal information is processed in accordance with our Privacy Policy.
      </p>

      <p><strong>4. Suspension and Closure</strong></p>
      <p>
        4.1 If you want to close your account, please contact us. Any negative balance on your account will fall immediately due and payable to tapabet , and your account will not be closed until the relevant amount owed to tapabet is paid in full.
      </p>
      <p>
        4.2 tapabet reserves the right to close or suspend your account at any time and for any reason. Without limiting the preceding sentence, tapabet shall be entitled to close or suspend your account if:4.2 tapabet reserves the right to close or suspend your account at any time and for any reason. Without limiting the preceding sentence, tapabet shall be entitled to close or suspend your account if:
      </p>
      <ol>
        <li>you become bankrupt;</li>
        <li>tapabet considers that you have used the Website in a fraudulent manner or for illegal and/or unlawful or improper purposes;</li>
        <li>tapabet considers that you have used the Website in an unfair manner, have deliberately cheated or taken unfair advantage of tapabet or any of its customers or if your account is being used for the benefit of a third party;</li>
        <li>tapabet is requested to do so by the police, any regulatory authority or court;</li>
        <li>tapabet considers that any of the events referred to in (a) to (c) above may have occurred or are likely to occur; or</li>
        <li>your account is deemed to be dormant and its balance is, or reaches zero in accordance with paragraph B.5.1 below.</li>
      </ol>
      
      <p>
        4.3 If tapabet closes or suspends your account for any of the reasons referred to in (a) to (e) above, you shall be liable for any and all claims, losses, liabilities, damages, costs and expenses incurred or suffered by tapabet (together “Claims”) arising therefrom and shall indemnify and hold tapabet harmless on demand for such Claims. In the circumstances referred to in (a) to (e) above, tapabet shall also be entitled to withhold and/or retain any and all amounts which would otherwise have been paid or payable to you (including any winnings or bonus payments).
      </p>

      <p><strong>4. Dormant Accounts</strong></p>
      <p>5.1 tapabet will apply an Administration Fee to all accounts that have been ‘inactive’ for a consecutive period of at least 365 days in accordance with the following procedure. An account is deemed to be ‘inactive’ during any period in which none of the following actions take place in relation to it: (i) a successful deposit; (ii) a sports or financials bet is placed.
      </p>
      <ol>
        <li>(a) If your account remains ‘inactive’ for a continuous period of 365 days then your account shall be deemed to be ‘dormant’ and, if the balance on your account is zero, your account will be closed and no fee shall apply.</li>

        <li>(b) If, on being deemed to be dormant, your account has a positive balance, tapabet shall take reasonable steps to notify you using the details you provided during your registration process (or as updated by you).</li>


        <li>(c) If your account remains dormant, after a minimum period of 28 days following tapabet ’s first attempt to notify you that your account has become dormant, tapabet shall deduct a monthly Administration Fee from your account balance of 200KSH (or currency equivalent), or 5% of your account balance at the time that it is deemed to be dormant (whichever is greater).</li>

        <li>(d) The Administration Fee calculated in accordance with (c) above shall be deducted from your account balance on the expiry of the 28 day notification period mentioned above and every 28 days thereafter at the same rate until the earlier of: (i) your account balance reaching zero when no further Administration Fee shall be deducted and your account will be closed; or (ii) you ‘reactivating’ your account when no further Administration Fee shall be deducted.</li>
      </ol>

      <p>5.2 You can ‘reactivate’ your account by: (i) making a successful deposit; (ii) placing a Sports or Financials bet; or (iii) playing/taking part in any Casino, Poker, Games, Vegas or Bingo product.</p>

      <p class="bold">C. YOUR FINANCES</p>
      <p><strong>1. Deposits and Wagers</strong></p>
      <p>1.1 You may only bet/wager with the amount of cleared funds held in your account. Accordingly, if you want to place bets or participate in gaming, you must deposit monies into your account. Further details of how to deposit, withdraw and transfer funds can be found on our website.</p>

      <p>1.2 By depositing funds into your account, you direct us and we agree to hold them, along with any winnings, for the sole and specific purpose of using them (i) to place your sporting and gaming stakes; and (ii) settling any fees or charges that you might incur in connection with the use of our services (Purpose). We shall be entitled to suspend or close your account if we reasonably consider or have reason to believe that you are depositing funds without any intention to place sporting and/or gaming stakes. In such circumstances we may also report this to relevant authorities.</p>

      <p>1.3 For payment methods that require a specific account holder, you should only deposit funds into your gambling account from a source where you are the named account holder. In such cases where tapabet identify that the named account holder differs from that which tapabet hold, we reserve the right to treat any deposits into the gambling account as being invalid (and any winnings arising from such deposit as void).</p>

      <p>1.4 All funds that we hold for you are held in separate designated customer bank accounts to be used solely for the Purpose and the bank has acknowledged this. We must return the funds to you if they are not used for the Purpose.</p>
      The bank has acknowledged that your funds are held in those accounts solely for the Purpose. The bank has confirmed that it will not mix customers’ funds in those accounts with any other, including our own, and that it has no claim on the funds itself.
      In view of the above, in the event of our insolvency, your funds are protected. The funds would still belong to you and would not fall into the hands of the liquidator.


      <p>1.5 You agree that we shall be entitled to retain any interest which might accrue on monies held in your tapabet account.</p>

      <p>1.6 No credit will be offered by any employee of tapabet , and all bets must be supported by sufficient funds in the customer account. tapabet reserves the right to void any bet/wager which may have inadvertently been accepted when the account did not have sufficient funds to cover the bet/wager. Should funds be credited to a customer's account in error it is the customer's responsibility to inform tapabet without delay. tapabet will recover such funds by account adjustment.</p>

      <p>1.7 Unless otherwise stated, all examples given on the Website are given in Kenyan Shillings. For any transaction which requires a currency exchange, the applicable exchange rate will be the mid-rate as quoted by the Financial Times.</p>

      <p>1.8 Once a currency is selected, funds are deposited, wagered and winnings paid in that currency. If you wish to change the currency used, please contact us.</p>

      <p>1.9 You are only entitled to use our currency exchange service for the purpose of placing bets or wagers via the Website (the “authorised purpose”). You are not entitled to use this service for any purpose other than the authorised purpose (including currency hedging, speculative trading or any other currency trading). If tapabet considers that you are using the Website for any reason other than the authorised purpose, tapabet shall be entitled to close or suspend your account and you shall:
      </p>
      <ol>
        <li>(a) be liable for any and all claims incurred arising therefrom; and</li>

        <li>(b) indemnify tapabet on demand for such claims.</li>
      </ol>

      <p>1.10 In addition, tapabet shall be entitled to withhold and/or retain any and all amounts earned or received by you as a result of or in connection with your ‘unauthorised’ use of the Website including activities which are not for an authorised purpose.</p>

      <p>1.11 tapabet does not charge for deposits made by Debit/Credit Card, however please be aware that some card issuers consider betting transactions as ‘cash’ payments and therefore may charge you a cash advance fee. Please check with your card issuer for further details.</p>


      <p><strong>2. Withdrawals</strong></p>
      <p>2.1 Where possible, all withdrawals will be processed to the payment account from which the deposits were made. Withdrawal payments can only be made in the name of and to the registered account holder.</p>

      <p>2.2 For most payment types, withdrawals can be processed by clicking 'Withdraw' on the Website, subject to there being sufficient funds in your betting account. There is no set maximum withdrawal amount per day but withdrawal requests for amounts greater than 100,000ksh or currency equivalent may require additional arrangements.</p>

      <p>2.3 We have a separate bank account for the sole and specific purpose of protecting money that you have asked to withdraw whilst it is on its way to you. We agree with you to maintain a balance of our money in that account for that sole and specific purpose. Therefore, in the event of our insolvency before the withdrawn amount reaches you, an equivalent amount in that account would belong to you and would not fall into the hands of the liquidator. The bank has acknowledged that the balance in that account is held solely for the specific purpose mentioned. The bank has confirmed that it will not mix the balance with any other, including our other balances, and that it has no claim on the funds itself.</p>

      <p>2.4 If the value of a deposit is not played through in full before a withdrawal is requested, tapabet reserves the right to make a charge to the customer’s account to cover all reasonable costs relating to both the deposit and withdrawal. If necessary, the value of the withdrawal requested may be reduced accordingly.</p>


      <p><strong>3. Other</strong></p>
      <p>3.1 If we incur any charge-backs, reversals or other charges in respect of your account, we reserve the right to charge you for the relevant amounts incurred.</p>

      <p>3.2 We may, at any time, set off any positive balance on your account against any amounts owed by you tapabet or any company within the tapabet group.</p>

      <p>3.3 You are responsible for reporting your winnings and losses to the tax and/or other authorities in your jurisdiction.</p>

      <p>3.4 Internet gambling may be illegal in the jurisdiction in which you are located. If so, you are not authorised to use your payment card to complete the transaction. However, any bets/wagers accepted from such jurisdictions will stand - win or lose.</p>


      <p class="bold">D. BETTING PROCEDURES</p>
      <p><strong>1. Placing Bets/Wagers</strong></p>
      <p>1.1 tapabet reserves the right to decline all, or part, of any bet/wager requested at its sole and absolute discretion. All bets/wagers are placed at your own risk and discretion. </p>

      <p>1.2 We only accept bets/wagers made online (including via mobile device or downloadable applications on a mobile device) or by mobile sms. Bets/wagers are not accepted in any other form (post, email, fax, etc.) and where received will be invalid and void - win or lose.</p>

      <p>1.3 It is the responsibility of the customer to ensure details of their bets/wagers are correct. Once bets/wagers have been placed they may not be cancelled or changed by the customer. tapabet reserves the right to cancel any bet/wager at any time.</p>

      <p>1.4 Your funds will be allocated to bets/wagers in the order they are placed and will not be available for any other use. tapabet reserves the right to void and/or reverse any transactions made after a bet/wager has been placed involving allocated funds, either at the time or retrospectively. </p>

      <p><strong>2. Bet/Wager Confirmation</strong></p>
      <p>2.1 Bets/wagers will not be valid if there are insufficient funds in your account. </p>

      <p>2.2 For telephone bets/wagers 'acceptance confirmed' will be deemed only after an operator has completed a full read back of the bet/wager details and confirmed to the customer that the bet/wager has been successfully processed and accepted. </p>

      <p>2.3 A bet/wager that you request will only be valid once accepted by tapabet ’s servers. Each valid bet/wager will receive a unique transaction code. We shall not be liable for the settlement of any bets/wagers which are not issued with a unique transaction code. If you are unsure about the validity of a bet/wager, please check your account history, or Contact Us.</p>

      <p>2.4 Notwithstanding the above, if bets/wagers are placed via a credit or debit card they are not valid until we have received payment in full. The bet/wager will be automatically void if we have not received the payment prior to the relevant event commencing. </p>

      <p>2.5 Should a dispute arise, you and tapabet agree that the Stonewood Enterprises transaction log database will be the ultimate authority in such matters. </p>

      <p><strong>3. Offers</strong></p>
      <p>3.1 All customer offers are limited to one per person, family, household address, phone Number, telephone number, same payment account number (e.g. debit or credit card), linked NETeller or Skrill accounts, mobile device (e.g. mobile or tablet), application download or shared computer (e.g. public library or workplace). </p>

      <p>3.2 Where any term of the offer or promotion is breached or there is any evidence of a series of bets/wagers placed by a customer or group of customers (e.g. where common betting patterns across the same fixture/markets have been identified across accounts), which due to a deposit bonus, enhanced payments, free and risk free bets/wagers, or any other promotional offer results in guaranteed customer profits irrespective of the outcome, whether individually or as part of a group, tapabet reserves the right to reclaim the bonus element of such offers and in tapabet ’s absolute discretion settle bets/wagers at the correct odds, void the free and risk free bets/wagers or void any bet/wager funded by the deposit bonus. In addition, tapabet reserves the right to levy an administration charge on the customer up to the value of the deposit bonus, free and risk free bet/wager or additional payment to cover administrative costs. tapabet further reserves the right to ask any customer to provide sufficient documentation for tapabet to be satisfied in its absolute discretion as to the customer’s identity prior to tapabet crediting any bonus, free and risk free bet/wager or offer to the customer’s account. </p>

      <p>3.3 Any bets/wagers placed containing selections at odds of less than 1/2 will not count towards any rollover requirement. All tapabet offers are intended for recreational players and tapabet may in its sole discretion limit the eligibility of customers to participate in all or part of any promotion.</p>

      <p>3.4 tapabet reserves the right to withdraw the availability of any offer or all offers to any customer or group of customers at any time and at tapabet’s sole and absolute discretion. Offers only apply to customers who have made an actual/real money deposit with tapabet .</p>

      <p>3.5 tapabet reserves the right to amend the terms of or cancel any customer offer or promotion at any time.</p>

      <p><strong>4. Settlement and Payouts</strong></p>
      <p>4.1 All bets and wagers are subject to the Betting Coverage provisions (including maximum winnings) set out in Appendix Two to these Terms and Conditions.</p>

      <p>4.2 tapabet reserves the right to suspend a market and/or cancel any bet/wager at anytime. When a market is suspended any bets entered will be rejected. tapabet also reserves the right to cease betting on any markets at any time without notice.</p>

      <p>4.3 The 'To Return' calculation available on the Website is for information purposes only, and all bets will be calculated using the stake/risk at the odds accepted. In multiple bets/parlays with a void selection(s) the 'To Return' figure is reduced accordingly.</p>

      <p>4.4 Should a customer include a non-runner or void selection in a multiple bet/parlay, the bet will be settled on the remaining selections.</p>

      <p>4.5 Winnings from settled bets/wagers are added to the balance of your betting account. Any funds/winnings credited to an account in error are not available for use, and tapabet reserves the right to void any transactions involving such funds and/or withdraw the relevant amount from your account and/or reverse the transaction, either at the time or retrospectively. </p>

      <p>4.6 tapabet reserves the right to withhold payment and to declare bets on an event void if we have evidence that the following has occurred: (i) the integrity of the event has been called into question; (ii) the price(s) or pool has been manipulated; or (iii) match rigging has taken place. Evidence of the above may be based on the size, volume or pattern of bets placed with tapabet across any or all of our betting channels. A decision given by the relevant governing body of the sport in question (if any) will be conclusive. If any customer owes any money to tapabet for any reason, we have the right to take that into account before making any payments to that customer. </p>

      <p>4.7 Where there is evidence of a series of bets each containing the same selection(s) having been placed by or for the same individual or syndicate of individuals, tapabet reserves the right to make bets void or withhold payment of returns pending the outcome of any subsequent investigation.</p>

      <p>4.8 For events where there is no official 'off' declared, the advertised start time of the event will be deemed the 'off'. If for any reason a bet is inadvertently accepted after an event or match has commenced (other than live In-Play betting clearly indicated on the Website), bets will stand providing the final outcome is not known, and that no participant/team has gained any material advantage (e.g. a score, sending off for the other team, etc) at the time the bet was placed. If the outcome of an event/market is known tapabet reserves the right to void the bet, win or lose. Disputes over times bets are placed will be settled using the transaction log. All times stated on the Website and/or referred to by tapabet staff relate to Kenyan Time unless stated otherwise. </p>

      <p>4.9 In-Play betting - where we have reason to believe that a bet is placed after the outcome of an event is known, or after the selected participant or team has gained a material advantage (e.g. a score, sending off for the other team etc) we reserve the right to void the bet, win or lose. </p>

      <p>4.10 If for any reason we are unable to validate the outcome of a particular market (e.g. due to loss of live pictures), all bets will be void, unless settlement of bets is already determined.</p>

      <p>4.11 Where a customer gives ambiguous instructions, tapabet reserves the right to split the amount of monies staked between the potential outcomes. Where this is impractical we reserve the right to void the whole of that bet. In any event, the decision of tapabet is final.</p>


      <p class="bold">E. USE OF THE WEBSITE</p>
      <p><strong>1. Information and Content</strong></p>
      <p>
        1.1 Information accessed by you on the Website (including results, statistics, sporting data and fixture lists, odds and betting figures) is for your personal use only and the distribution or commercial exploitation of such information is strictly prohibited. No warranty is given as to the uninterrupted provision of such information, its accuracy or as to the results obtained through its use. The information is not intended to amount to advice or recommendations and is provided for information purposes only. It should not be relied upon when placing bets/wagers, which are made at your own risk and discretion.
      </p>

      <p><strong>2. Your Equipment</strong></p>
      <p>2.1 Your computer equipment or mobile device and internet connection may affect the performance and/or operation of the Website. tapabet does not guarantee that the Website will operate without faults or errors or that the tapabet services will be provided without interruption. tapabet does not accept any liability for any failures or issues that arise due to your equipment, internet connection or internet or telecommunication service provider (including, for example, if you are unable to place bets or wagers or to view or receive certain information in relation to particular events). </p>

      <p>2.2 For customers using tapabet On The Move (including downloadable applications) for the placing of bets/wagers, please note that tapabet will not be responsible for any damage to, or loss of data from the mobile device that the software is installed on (unless such damage or loss is solely and directly attributable to the downloaded software) and will also not be responsible for any call, data or other charges incurred whilst using the software. </p>

      <p><strong>3. Fair Use</strong></p>
      <p>3.1 The Website and tapabet products may only be used for the purposes of placing bets and wagers on events and/or gaming products. </p>

      <p>3.2 You must not use the Website for the benefit of a third party or for any purpose which (in tapabet’s opinion) is illegal, defamatory, abusive or obscene, or which tapabet considers discriminatory, fraudulent, dishonest or inappropriate.</p>

      <p>3.3 tapabet will seek criminal and contractual sanctions against any customer involved in fraudulent, dishonest or criminal acts via or in connection with the Website or tapabet 's products. tapabet will withhold payment to any customer where any of these are suspected or where the payment is suspected to be for the benefit of a third party. The customer shall indemnify and shall be liable to pay to tapabet , on demand, all Claims (as defined in paragraph B.4.3 above) arising directly or indirectly from the customer’s fraudulent, dishonest or criminal act.</p>

      <p><strong>4. Software and Technology Issues</strong></p>
      <p>4.1 In order for you to use certain products offered on the Website you may need to download some software (for example, casino games that are made available via a flash player). Also, certain third party product providers may require you to agree to additional terms and conditions governing the use of their products. If you do not accept those third party terms and conditions, do not use the relevant third party software. tapabet does not accept any liability in respect of any third party software. </p>

      <p>4.2 You are only permitted to use any and all software made available to you via the Website for the purpose of using products on the Website and, save to the extent permitted by applicable law, for no other purposes whatsoever.</p>

      <p>4.3 We hereby grant to you a personal, non-exclusive, non-transferable right to use the relevant software, for the sole purpose of using/playing products on the Website (including the online casino), in accordance with the following provisions. 
      <ol>
        <li>(a) You are not permitted to: (i) install or load the software onto a server or other networked device or take other steps to make the software available via any form of "bulletin board", online service or remote dial-in or network to any other person; (ii) sub-license, assign, rent, lease, loan, transfer or copy (except as expressly provided elsewhere in these terms and conditions) your licence to use the software or make or distribute copies of the software; (iii) enter, access or attempt to enter or access or otherwise bypass tapabet ’s security system or interfere in any way (including but not limited to, robots and similar devices) with the relevant products or the Website or attempt to make any changes to the software and/or any features or components thereof; or (iv) copy or translate any user documentation provided 'online' or in electronic format. In addition, and except to the minimum extent permitted by applicable law in relation to computer programs you are not permitted to: (a) translate, reverse engineer, decompile, disassemble, modify, create derivative works based on, or otherwise modify the software; or (b) reverse engineer, decompile, disassemble, modify, adapt, translate, make any attempt to discover the source code of the software or to create derivative works based on the whole or on any part of the software. </li>

        <li>(b) You do not own the software. The software is owned and is the exclusive property of Stonewood Enterprises or a third party software provider company (the "Software Provider"). Any software and accompanying documentation which have been licensed to Stonewood Enterprises are proprietary products of the Software Provider and protected throughout the world by copyright law. Your use of the software does not give you ownership of any intellectual property rights in the software. </li>

        <li>(c) The software is provided "as is" without any warranties, conditions, undertakings or representations. tapabet does not warrant that: (i) the software will meet your requirements; (ii) the software will not infringe any third party’s intellectual property rights; (iii) the operation of the software will be error free or uninterrupted; (iv) any defects in the software will be corrected; or (v) the software or the servers are virus-free. </li>

        <li>(d) In the event of communications or system errors occurring in connection with the settlement of accounts or other features or components of the software, neither tapabet nor the Software Provider will have any liability to you or to any third party in respect of such errors. tapabet reserves the right in the event of such errors to remove all relevant products from the Website and take any other action to correct such errors. </li>

        <li>(e) You hereby acknowledge that how you use the software is outside of tapabet ’s control and at your own risk. </li>

        <li>(f) The software may include confidential information which is secret and valuable to the Software Provider and/or tapabet. You are not entitled to use or disclose that confidential information other than strictly in accordance with these Terms and Conditions.</li>
      </ol>

      <p>4.4 While tapabet endeavours to ensure that the Website is available 24 hours a day, tapabet shall not be liable if for any reason the Website is unavailable at any time or for any period. We reserve the right to make changes or corrections to or to alter, suspend or discontinue any aspect of the Website and the content or services or products available through it, including your access to it.</p>

      <p>4.5 You must not misuse the Website by introducing viruses, Trojans, worms, logic bombs or other material which is malicious or technologically harmful. In particular, you must not access without authority, interfere with, damage or disrupt the Website or any part of it; any equipment or network on which the Website is stored; any software used in connection with the provision of the Website; or any equipment, software or website owned or used by a third party. You must not attack our Website via a denial-of-service attack. Save where we have not taken reasonable steps, we will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of the Website, software or to your downloading of any material posted on it, or on any website linked to it.</p>


      <p><strong>5. Third Party Content</strong></p>
      <p>5.1 tapabet receives feeds, commentaries and content from a number of suppliers. Certain third party product providers may require you to agree to additional terms and conditions governing the use of their feeds, commentaries and content. If you do not accept the relevant third party terms and conditions, do not use the relevant feeds, commentaries or content.</p>

      <p>5.2 tapabet does not accept any liability in respect of any third party feeds, commentaries and content.</p>

      <p>5.3 tapabet does not allow any employee, anyone else in any way connected to such employee or anyone otherwise connected to a third party service provider (to be determined in the Company's absolute discretion) to bet/wager on any market or event where the third party service provider is providing a service to tapabet. tapabet will void any bet/wager where it determines in its absolute discretion that such betting/wagering has taken place.</p>

      <p>5.4 Where the Mobile service, Website and all other media/electronic platforms, contains links to third party resources, these links are provided for your information only. tapabet has no control over the content of these sites or resources, and accepts no liability for them or for any loss or damage that may arise from your use of them. The inclusion of a link to a third party does not constitute an endorsement of that third party's product or services (if applicable).</p>


      <p><strong>6. Errors</strong></p>
      <p>6.1 tapabet will not be liable for any errors in respect of bets or wagers including where:</p>
      <ol>
        <li>1. (i) the Company has incorrectly stated the relevant odds/spreads/handicap/totals;</li>
        <li>2. (ii) the Company continues to accept bets or wagers on closed or suspended markets;</li>
        <li>3. (iii) the Company incorrectly calculates or pays a settlement amount; or</li>
        <li>4. (iv) Any error occurs in a random number generator or pay tables included, incorporated or used in any game or product.</li>
      </ol>

      <p>6.2 Incorrect Price - Prior to the start of an event, In-Play or after the event, where an Obvious Error is identified any open bets will stand and be settled at the Company revised price. Where there is sufficient time before the start of an event, the Company will endeavour to contact the customer and may in our absolute discretion allow the option of cancelling the bet.</p>
      <p>6.3 Incorrect Count/Line/Spread/Handicap/Total -Prior to the start of an event, In-Play or after the event, where an Obvious Error is identified any open bets will stand and be settled at the count, line, spread, handicap or total taken at the Company revised price except for the following situations:</p>
      <ol>
        <li>1. (i) where a revised price is deemed less than 1/1000 then bets will be void.</li>
        <li>2. (ii) any bet placed on a count, line, spread, handicap or total where the outcome is already known when the bet was placed will be void.</li>
      </ol>

      <p>6.4 Where there is sufficient time before the start of an event, the Company will endeavour to contact the customer and may in our absolute discretion allow the option of cancelling the bet.</p>
      <p>6.5 Incorrect Fixture - Where the wrong player or team is quoted within a fixture name all bets will be void. Such determination shall be in the Company's absolute discretion.</p>
      <p>6.6 Wrong Participant - If a wrong participant is quoted for any match or event, bets placed on that participant will be void; other participants may also be void. Such determination shall be in the Company's absolute discretion.</p>
      <p>6.7 Late Bets - If for any reason a pre-event bet is inadvertently accepted after a match or event has commenced, bets will be settled as follows:</p>
      <ol>
        <li>1. (a) If the event and market is covered In-Play then bets will stand at the revised price at the time the bet was placed (where a revised price is deemed less than 1/1000 then bets will be void), unless the outcome is already known in which case such bets will be void.</li>
        <li>2. (b) If the event or market is not covered In-Play then the bet will stand as long as the selected participant or team has not gained a material advantage (e.g. a score, sending off for the other team etc). Where a material advantage has been gained the Company reserve the right to void the bet, win or lose. Any bet placed where the outcome is already known, including In-Play, will be made void.</li>
      </ol>


      <p><strong>7. Other</strong></p>

      <p>7.1 tapabet actively monitors traffic to and from the Website. tapabet reserves the right in its sole discretion to block access where evidence indicative of automated or robotic activity is found.</p>

      <p>7.2 tapabet reserves the right to restrict access to all or certain parts of the Website in respect of certain jurisdictions.</p>

      <p>7.3 tapabet may amend the products offered via the Website at any time and for any reason</p>

      <p>7.4 From time to time, all or part of the Website may be unavailable for because of our maintenance of the Website and/or alteration or amendment of any of the products.</p>


      <p class="bold">F. OUR LIABILITY</p>
      <p>tapabet does not accept any liability for any damages, liabilities or losses which are deemed or alleged to have arisen out of or in connection with the Website or its content (including delays or interruptions in operation or transmission, communication or lines failure, any person's misuse of the Website or its content or any errors or omissions in content). </p>

      <p>While tapabet endeavours to ensure that the information on the Website is correct, tapabet does not warrant the accuracy or completeness of the information and material on the Website. The Website may contain typographical errors or other inaccuracies, or information that is out of date. tapabet is under no obligation to update such material. The information and material on the Website is provided “as is”, without any conditions, warranties or other terms of any kind. Accordingly, to the maximum extent permitted by law, tapabet provides you with the Website on the basis that tapabet excludes all representations, express or implied warranties, conditions and other terms which but for these terms and conditions might have effect in relation to the Website.</p>

      <p>tapabet ’s total aggregate liability to you under or in connection with these Terms and Conditions does not exceed:</p>
      <ol>
        <li>the value of the bets and/or wagers you placed via your account in respect of the relevant bet/wager or product that gave rise to the relevant liability; and</li>

        <li>the amount of applicable monies, where such monies have been misplaced by us.</li>
      </ol>

      <p>4. Except as otherwise set out in these terms, tapabet shall not be liable, in contract, tort (including negligence) or for breach of statutory duty or in any other way for any of the following (whether incurred directly or indirectly):
      <ol>
        <li>loss of profits;</li>

        <li>loss of business;</li>

        <li>loss of revenue;</li>

        <li>loss of opportunity;</li>

        <li>loss of data;</li>

        <li>loss of goodwill or reputation; or</li>

        <li>any special, indirect or consequential losses, </li>

      </ol>
      <p>
      whether or not such losses were within the contemplation of the parties at the date of these terms and conditions.
      </p>

      <p class="bold">G. OUR INTELLECTUAL PROPERTY RIGHTS</p>

      <p>1. The contents of the Website are protected by international copyright laws and other intellectual property rights. The owner of these rights is tapabet , its affiliates or other third party licensors.</p>

      <p>2. All product and company names and logos mentioned on the Website are the trademarks, service marks or trading names of their respective owners, including tapabet .</p>

      <p>3. Except to the extent required to use a product for the purpose of placing bets or wagers, no part of the Website may be reproduced or stored, modified, copied, republished, uploaded, posted, transmitted or distributed, by any means or in any manner, or included in any other website or in any public or private electronic retrieval system or service including text, graphics, video, messages, code and/or software without our express prior written consent.</p>

      <p>4. If you make use of a feature that allows you to upload material, information, comments, postings or other content to the Website ("User Content"), then the User Content will be considered to be non-confidential and non-proprietary and tapabet has the right to use, copy, distribute and disclose to third parties any User Content for any purpose. tapabet also has the right to disclose your identity to any third party who is claiming that any User Content posted or uploaded by you to the Website constitutes a violation of their intellectual property rights or of their right to privacy. tapabet has the right to remove, amend or edit any User Content you make on the Website.</p>

      <p>5. Any commercial use or exploitation of the Website or its content is strictly prohibited.</p>


      <p class="bold">H. OTHER PROVISIONS</p>

      <p>1. These Terms and Conditions, the Privacy Policy, the Cookies Policy, the Rules and any document expressly referred to in them and any guidelines or rules posted on the Website constitute the entire agreement and understanding of the parties and supersede any previous agreement between the parties relating to their subject matter. You acknowledge and agree that in entering into and agreeing to these Terms and Conditions, the Privacy Policy, the Cookies Policy, the Rules and any document expressly referred to in them and any guidelines or rules posted on the Website you do not rely on, and shall have no remedy in respect of, any statement, representation, warranty, understanding, promise or assurance (whether negligently or innocently made) of any person (whether party to this agreement or not) other than as expressly set out therein. Nothing in this clause shall operate to limit or exclude any liability for fraud or fraudulent misrepresentation.</p>

      <p>2. In no event will any delay, failure or omission (in whole or in part) in enforcing, exercising or pursuing any right, power, privilege, claim or remedy conferred by or arising under these Terms and Conditions or by law, be deemed to be or construed as a waiver of that or any other right, power, privilege, claim or remedy in respect of the circumstances in question, or operate so as to bar the enforcement of that, or any other right, power, privilege, claim or remedy, in any other instance at any time or times subsequently.</p>

      <p>3. The rights and remedies provided by these Terms and Conditions are cumulative and (unless otherwise provided in these Terms and Conditions) do not exclude any other rights or remedies available in law.</p>

      <p>4. If any provision of these Terms and Conditions is found by any court or administrative body of competent jurisdiction to be invalid or unenforceable, such invalidity or unenforceability shall not affect the other provisions of these Terms and Conditions which shall remain in full force and effect.</p>

      <p>5. You shall execute or cause to be executed all documents and do or cause to be done all further acts and things consistent with the terms of these Terms and Conditions that tapabet  may from time to time reasonably require in order to vest in and secure to tapabet  the full benefit of rights and benefits to be transferred or granted to tapabet  under these Terms and Conditions and for the protection and enforcement of the same and otherwise to give full effect to the terms of these Terms and Conditions.</p>

      <p>6. Nothing in these Terms and Conditions shall create or be deemed to create a partnership, joint venture or principal-agent relationship between the parties and no party shall have authority to bind any other in any way unless expressly provided otherwise in these Terms and Conditions.</p>

      <p>7. tapabet  shall not be in breach of these Terms and Conditions nor liable for delay in performing, or failure to perform, any of its obligations if such delay or failure results from events, circumstances or causes beyond its reasonable control including (without limitation) any telecommunications network failures, power failures, failures in third party computer hardware or software, fire, lightning, explosion, flood, severe weather, industrial disputes or lock-outs, terrorist activity and acts of government or other competent authorities. In such circumstances the time for performance shall be extended by a period equivalent to the period during which performance of the obligation has been delayed or failed to be performed.</p>

      <p>8. tapabet may assign, transfer, charge, sub-license or deal in any other manner with these Terms and Conditions, or sub-contract any of its rights and obligations under these Terms and Conditions, to any party including any company within the tapabet group.</p>

      <p>9. Any notice to be given under these Terms and Conditions must be in writing, in English and may be served by hand delivery, first class post, recorded delivery or registered post or airmail, email or fax to: (a) in the case of tapabet , the address of the relevant tapabet company as set out at the beginning of these Terms and Conditions or as displayed on the Website; and (b) in respect of notices given by tapabet to you, pursuant to the customer registration procedure (including any amendment to those details you have notified to tapabet ). Any notice shall be deemed to have been received: (a) if delivered by hand, at the time of delivery; (b) if sent by first class post, recorded delivery or registered post, at 09.30 (GMT) on the second clear day after the date of posting; (c) if sent by pre-paid registered airmail, at 09.30 (GMT) on the fifth clear day after the date of posting; (d) if sent by email, at the time of sending; and (e) if sent by fax, at the time of transmission by the sender.</p>


      <p>10. The Appendices, the Privacy Policy, the Cookies Policy, the Rules and any document expressly referred to in them and any guidelines or rules posted on the Website form an integral part of these Terms and Conditions and shall have effect as if set out in full in the body of these Terms and Conditions. In the event of any inconsistency between the main body of these Terms and Conditions and the Appendices, the Privacy Policy, the Cookies Policy, the Rules and/or any document expressly referred to in them and any guidelines or rules posted on the Website, unless stated otherwise, the main body shall prevail. </p>


    </div>

    <BottomFooter></BottomFooter>

    <bottom-navigation active-bets="0" page="login"></bottom-navigation>

  </div>

</template>

<script>
import BottomFooter from "@/components/BottomFooter";
import BottomNavigation from "@/components/BottomNavigation";

export default {
  name: 'Terms',
  components: {
    BottomFooter,
    BottomNavigation
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage","terms");
    this.reloadProfile();

  }

}
</script>